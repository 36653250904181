<template>
  <div id="student-candidate-detail">
    <a-modal
      :visible="visibleModal"
      :title="modalOpt.title"
      centered
      :width="800"
      :footer="null"
      @cancel="cancelModal"
    >
      <table-parents class="mb-4" v-if="modalOpt.key === 'orangtua'" :datas="modalOpt.data"></table-parents>
      <table-achievement class="mb-4" v-if="modalOpt.key === 'prestasi'" :datas="modalOpt.data"></table-achievement>
      <table-scholarship class="mb-4" v-if="modalOpt.key === 'beasiswa'" :datas="modalOpt.data"></table-scholarship>
      <div v-if="modalOpt.key === 'lokasi'" class="mb-4" style="width: 100%; height: 400px;">
        <location :datas="modalOpt.data"></location>
      </div>
    </a-modal>
    <filter-data :visible="showFilter" @save="saveFilter" @cancel="cancelFilter" :filterData="filter" />
    <h1>Detil Kandidat Murid</h1>
    <card>
      <div class="mb-3 d-flex">
        <div>
          <a-input
            v-model="search"
            @change="searchName"
            placeholder="Cari berdasarkan nama.."
            :style="{
              minWidth: '325px',
              height: '40px'
            }"
          ></a-input>
        </div>
        <div class="ml-3">
          <a-select size="large" v-model="batch" @change="fetchData" style="min-width: 200px" placeholder="Pilih Tahun Masuk">
            <a-select-option v-for="tahun in years" :key="tahun.tahun" :value="tahun.tahun">{{tahun.tahun}}</a-select-option>
          </a-select>
        </div>
        <div class="ml-auto text-right">
          <a-button
          @click.prevent="showFilter = true"
          size="large" icon="filter" type="primary">SARING DATA</a-button>
        </div>
      </div>
      <div>
        <a-table
          :columns="columns"
          :data-source="dataTable"
          :pagination="pagination"
          :loading="loadingTable"
          @change="handleChangeTable"
          bordered
          :scroll="{ x: 300 }"
        >
          <div slot="nullStr" slot-scope="text">{{text || '-'}}</div>
          <div slot="kg" slot-scope="text">{{text ? `${text} kg` : '-'}}</div>
          <div slot="cm" slot-scope="text">{{text ? `${text} cm` : '-'}}</div>
          <div slot="km" slot-scope="text">{{text ? `${text} km` : '-'}}</div>
          <div slot="boolean" slot-scope="text">
            <a-tag v-if="!text" color="green">Tidak</a-tag>
            <a-tag v-else color="green">Ya</a-tag>
          </div>
          <div slot="jsonRead" slot-scope="text">{{text ? text.deskripsi : '-'}}</div>
          <span slot="buttonOrtu" slot-scope="text, record">
            <a-button @click.prevent="showModal(record, 'orangtua')" :type="text.length ? 'primary' : 'default'" size='large' :icon="text.length ? 'eye' : 'eye-invisible'" :disabled="!text.length">{{text.length ? 'Lihat Daftar' : 'Tidak Ada Data'}}</a-button>
          </span>
          <span slot="buttonPrestasi" slot-scope="text, record">
            <a-button @click.prevent="showModal(record, 'prestasi')" :type="text.length ? 'primary' : 'default'" size='large' :icon="text.length ? 'eye' : 'eye-invisible'" :disabled="!text.length">{{text.length ? 'Lihat Daftar' : 'Tidak Ada Data'}}</a-button>
          </span>
          <span slot="buttonBeasiswa" slot-scope="text, record">
            <a-button @click.prevent="showModal(record, 'beasiswa')" :type="text.length ? 'primary' : 'default'" size='large' :icon="text.length ? 'eye' : 'eye-invisible'" :disabled="!text.length">{{text.length ? 'Lihat Daftar' : 'Tidak Ada Data'}}</a-button>
          </span>
          <span slot="buttonLokasi" slot-scope="text, record">
            <a-button @click.prevent="showModal(record, 'lokasi')" :type="text ? 'primary' : 'default'" size='large' :icon="text ? 'environment' : 'eye-invisible'" :disabled="!text">{{text ? 'Lihat Lokasi' : 'Tidak Ada Data'}}</a-button>
          </span>
          <div slot="berkebutuhan_khusus" slot-scope="text, record">
            <a-tag v-if="!record.berkebutuhan_khusus" color="green">Tidak</a-tag>
            <template v-else>
              <a-tag v-if="!record.berkebutuhan_khusus.length" color="green">tidak</a-tag>
              <template v-else>
                <a-tag class="m-1" v-for="kebutuhan in record.berkebutuhan_khusus" :key="kebutuhan.key" color="orange">{{kebutuhan.deskripsi}}</a-tag>
              </template>
            </template>
          </div>
          <div slot="jenis_kelamin" slot-scope="text">{{text === 'Laki-Laki' ? 'L' : text === 'Perempuan' ? 'P' : '-'}}</div>
          <div slot="status_ppdb" slot-scope="text">
            <a-tag v-if="text === 'accepted'" color="green">Diterima</a-tag>
            <a-tag v-else-if="text === 'pending'" color="orange">Menunggu</a-tag>
            <a-tag v-else-if="text === 'rejected'" color="red">Ditolak</a-tag>
            <div v-else>-</div>
          </div>
        </a-table>
      </div>
    </card>
  </div>
</template>

<script>
import moment from 'moment'
const Card = () => import('@/components/app/Card')
const FilterData = () => import('./Filter')
const TableParents = () => import('./Table/Parents')
const TableAchievement = () => import('./Table/Achievement')
const TableScholarship = () => import('./Table/Scholarship')
const Location = () => import('./Location')
const columns = [
  {
    title: 'NIPD',
    dataIndex: 'nis',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'nullStr',
    },
    fixed: 'left',
  },
  {
    title: 'Nama',
    dataIndex: 'nama',
    align: 'center',
    width: 300,
    scopedSlots: {
      customRender: 'nullStr',
    },
    fixed: 'left',
  },
  {
    title: 'Status PPDB',
    dataIndex: 'status_ppdb',
    align: 'center',
    width: 150,
    scopedSlots: {
      customRender: 'status_ppdb',
    },
  },
  {
    title: 'JK',
    dataIndex: 'jenis_kelamin',
    align: 'center',
    width: 75,
    scopedSlots: {
      customRender: 'jenis_kelamin',
    },
  },
  {
    title: 'NISN',
    dataIndex: 'nisn',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'Tempat Lahir',
    dataIndex: 'tempat_lahir',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'Tanggal Lahir',
    dataIndex: 'tanggal_lahir',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'NIK',
    dataIndex: 'nik_kitas',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'Agama',
    dataIndex: 'agama',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'Orangtua',
    dataIndex: 'orangtuas',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'buttonOrtu',
    },
  },
  {
    title: 'Alamat Rumah',
    align: 'center',
    children: [
      {
        title: 'Alamat',
        dataIndex: 'jalan',
        align: 'center',
        width: 200,
        scopedSlots: {
          customRender: 'nullStr',
        },
      },
      {
        title: 'RT',
        dataIndex: 'rt',
        align: 'center',
        width: 75,
        scopedSlots: {
          customRender: 'nullStr',
        },
      },
      {
        title: 'RW',
        dataIndex: 'rw',
        align: 'center',
        width: 75,
        scopedSlots: {
          customRender: 'nullStr',
        },
      },
      {
        title: 'Dusun',
        dataIndex: 'dusun',
        align: 'center',
        width: 200,
        scopedSlots: {
          customRender: 'nullStr',
        },
      },
      {
        title: 'Kelurahan',
        dataIndex: 'kelurahan',
        align: 'center',
        width: 200,
        scopedSlots: {
          customRender: 'nullStr',
        },
      },
      {
        title: 'Kecamatan',
        dataIndex: 'kecamatan',
        align: 'center',
        width: 200,
        scopedSlots: {
          customRender: 'nullStr',
        },
      },
      {
        title: 'Kode Pos',
        dataIndex: 'kode_pos',
        align: 'center',
        width: 200,
        scopedSlots: {
          customRender: 'nullStr',
        },
      },
      {
        title: 'Lokasi Rumah',
        dataIndex: 'geolokasi_tempat_tinggal',
        align: 'center',
        width: 300,
        scopedSlots: {
          customRender: 'buttonLokasi',
        },
      },
      {
        title: 'Jarak Rumah Ke Sekolah',
        dataIndex: 'jarak_sekolah',
        align: 'center',
        width: 300,
        scopedSlots: {
          customRender: 'km',
        },
      },
    ],
  },
  {
    title: 'Jenis Tinggal',
    dataIndex: 'tempat_tinggal',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'jsonRead',
    },
  },
  {
    title: 'Alat Transportasi',
    dataIndex: 'moda_transportasi',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'jsonRead',
    },
  },
  {
    title: 'Kontak Murid',
    align: 'center',
    children: [
      {
        title: 'Telepon',
        dataIndex: 'nomor_telepon',
        align: 'center',
        width: 200,
        scopedSlots: {
          customRender: 'nullStr',
        },
      },
      {
        title: 'HP',
        dataIndex: 'nomor_hp',
        align: 'center',
        width: 200,
        scopedSlots: {
          customRender: 'nullStr',
        },
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        align: 'center',
        width: 200,
        scopedSlots: {
          customRender: 'nullStr',
        },
      },
    ],
  },
  {
    title: 'KPS',
    align: 'center',
    children: [
      {
        title: 'Penerima KPS',
        dataIndex: 'kps_pkh',
        key: 'kps',
        align: 'center',
        width: 200,
        scopedSlots: {
          customRender: 'boolean',
        },
      },
      {
        title: 'No. KPS/PKH',
        dataIndex: 'kps_pkh',
        key: 'no_kps',
        align: 'center',
        width: 200,
        scopedSlots: {
          customRender: 'nullStr',
        },
      },
    ],
  },
  {
    title: 'KIP',
    align: 'center',
    children: [
      {
        title: 'Penerima KIP',
        dataIndex: 'penerima_kip',
        align: 'center',
        width: 300,
        scopedSlots: {
          customRender: 'boolean',
        },
      },
      {
        title: 'Nomor KIP',
        dataIndex: 'nomor_kip',
        align: 'center',
        width: 300,
        scopedSlots: {
          customRender: 'nullStr',
        },
      },
      {
        title: 'Nama di KIP',
        dataIndex: 'nama_kip',
        align: 'center',
        width: 300,
        scopedSlots: {
          customRender: 'nullStr',
        },
      },
    ],
  },
  {
    title: 'Nomor KKS',
    dataIndex: 'nomor_kks',
    align: 'center',
    width: 300,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'Nomor Registrasi Akta Lahir',
    dataIndex: 'akta_lahir',
    align: 'center',
    width: 300,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'PIP',
    align: 'center',
    children: [
      {
        title: 'Layak PIP (usulan dari sekolah)',
        dataIndex: 'layak_pip',
        align: 'center',
        width: 300,
        scopedSlots: {
          customRender: 'boolean',
        },
      },
      {
        title: 'Alasan Layak PIP',
        dataIndex: 'alasan_layak_pip',
        align: 'center',
        width: 300,
        scopedSlots: {
          customRender: 'nullStr',
        },
      },
    ],
  },
  {
    title: 'Berkebutuhan Khusus',
    align: 'center',
    width: 300,
    scopedSlots: {
      customRender: 'berkebutuhan_khusus',
    },
  },
  {
    title: 'Tanggal Masuk Sekolah',
    dataIndex: 'tanggalMasuk',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'SKHUN',
    dataIndex: 'nomor_seri_skhus',
    align: 'center',
    width: 200,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'No. Peserta Ujian Nasional',
    dataIndex: 'nomor_peserta_ujian',
    align: 'center',
    width: 300,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'No. Seri Ijazah',
    dataIndex: 'nomor_seri_ijazah',
    align: 'center',
    width: 300,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'Sekolah Asal',
    dataIndex: 'sekolah_lalu',
    align: 'center',
    width: 300,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'Anak Keberapa',
    dataIndex: 'anak_ke',
    align: 'center',
    width: 120,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'Jml. Saudara Kandung',
    dataIndex: 'jumlah_saudara_kandung',
    align: 'center',
    width: 150,
    scopedSlots: {
      customRender: 'nullStr',
    },
  },
  {
    title: 'Berat Badan',
    dataIndex: 'berat_badan',
    align: 'center',
    width: 150,
    scopedSlots: {
      customRender: 'kg',
    },
  },
  {
    title: 'Tinggi Badan',
    dataIndex: 'tinggi_badan',
    align: 'center',
    width: 150,
    scopedSlots: {
      customRender: 'cm',
    },
  },
  {
    title: 'Lingkar Kepala',
    dataIndex: 'lingkar_kepala',
    align: 'center',
    width: 150,
    scopedSlots: {
      customRender: 'cm',
    },
  },
  {
    title: 'Prestasi',
    dataIndex: 'prestasis',
    align: 'center',
    width: 300,
    scopedSlots: {
      customRender: 'buttonPrestasi',
    },
  },
  {
    title: 'Beasiswa',
    dataIndex: 'beasiswas',
    align: 'center',
    width: 300,
    scopedSlots: {
      customRender: 'buttonBeasiswa',
    },
  },
]

export default {
  name: 'DetailStudentCandidate',
  components: { Card, FilterData, TableParents, Location, TableAchievement, TableScholarship },
  data() {
    return {
      columns,
      search: null,
      customStyle:
        'background: white;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden',
      filter: {
        agama: '',
        kewarganegaraan: '',
        berkebutuhan_khusus: false,
        // berkebutuhan_khusus: [],
        geolokasi_tempat_tinggal: '',
        rentang_penghasilan: '',
        jenis_bantuan: '',
        pencapaian: '',
        jenis_kelamin: '',
      },
      pagination: {},
      dataTable: [],
      loadingTable: false,
      showFilter: false,
      years: [],
      batch: null,
      visibleModal: false,
      modalOpt: {
        title: null,
        key: null,
        data: null,
      },
      tableOpt: {
        order: 'ASC',
        sort: 'id',
      },
    }
  },
  methods: {
    async fetchData() {
      try {
        this.loadingTable = true
        const { current } = this.pagination
        const { data, total } = await this.$store.dispatch('master/FETCH_DETAIL_CANDIDATE', { filter: this.filter, batch: this.batch, search: this.search, page: current })
        this.dataTable = data.map(murid => {
          const ttl = murid.tempat_tanggal_lahir || {}
          const alamat = murid.alamat_lengkap || {}
          const jarakSekolah = murid.geolokasi_tempat_tinggal?.jarak
          const kotaSekolah = murid.asal_sekolah?.provinsi === 'Dki Jakarta' ? murid.asal_sekolah.provinsi : murid.asal_sekolah?.kota
          const sekolahAsal = murid.asal_sekolah ? `${murid.asal_sekolah.nama} ${kotaSekolah}` : null
          return {
            ...murid,
            ...alamat,
            key: murid.id,
            tanggal_lahir: ttl.tanggal ? moment(ttl.tanggal, 'YYYY-MM-DD').locale('id').format('DD MMMM YYYY') : null,
            tempat_lahir: ttl.tempat,
            jarak_sekolah: jarakSekolah,
            sekolah_lalu: sekolahAsal,
            tanggalMasuk: murid.tanggal_masuk_sekolah ? moment(murid.tanggal_masuk_sekolah, 'YYYY-MM-DD').locale('id').format('DD MMMM YYYY') : null,
          }
        })
        this.pagination = { ...this.pagination, total }

        const queries = {}
        for (const query in this.filter) {
          const data = this.filter[query]
          if (data) {
            queries[query] = data
          }
        }
        queries.tahun_masuk = this.batch
        this.$router.replace({
          name: this.$route.name,
          query: queries,
        }).catch(() => {})
        // console.log(total)
        this.loadingTable = false
        return new Promise((resolve) => resolve())
      } catch (err) {
        this.loadingTable = false
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    async fetchBatch() {
      try {
        const { data, yearsActive } = await this.$store.dispatch('admin/FETCH_TAHUN_MASUK')
        this.years = data
        const dataActive = this.years.find(year => year.tahun === yearsActive?.tahun)
        if (dataActive) {
          this.batch = dataActive.tahun
        } else {
          this.batch = this.years[0].tahun
        }
        return new Promise((resolve) => resolve())
      } catch (err) {
        console.log(err)
        return new Promise((resolve, reject) => reject(err))
      }
    },
    cancelFilter() { this.showFilter = false },
    saveFilter(filter) {
      this.filter = filter
      this.fetchData()
      this.showFilter = false
    },
    showModal(data, key) {
      this.visibleModal = true
      let title = ''
      if (key === 'lokasi') title = `Data Lokasi Rumah ${data.nama}`
      else if (key === 'orangtua') title = `Data Orangtua ${data.nama}`
      else if (key === 'prestasi') title = `Data Prestasi ${data.nama}`
      else if (key === 'beasiswa') title = `Data Beasiswa ${data.nama}`

      this.modalOpt = {
        title,
        data,
        key,
      }
    },
    cancelModal() {
      this.visibleModal = false
      setTimeout(() => {
        this.modalOpt = {
          title: null,
          data: null,
          key: null,
        }
      }, 300)
    },
    handleChangeTable(pagination, filters, sorter) {
      const pager = { ...this.pagination }
      pager.current = pagination.current
      this.pagination = pager
      if (sorter.order === 'ascend') {
        this.tableOpt.order = 'ASC'
      } else if (sorter.order === 'descend') {
        this.tableOpt.order = 'DESC'
      } else {
        this.tableOpt.order = 'ASC'
      }
      this.fetchData()
    },
    searchName() {
      this.pagination = { ...this.pagination, current: 1 }
      this.fetchData()
    },
  },
  async created() {
    try {
      await this.fetchBatch()
      const queries = this.$route.query
      for (const query in queries) {
        const data = queries[query]
        if (query !== 'tahun_masuk') {
          this.filter[query] = data
        } else {
          this.batch = data
        }
      }
      this.fetchData()
    } catch (err) {
      console.log(err)
    }
  },
}
</script>

<style>

</style>
